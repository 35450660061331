// .App {
//   text-align: center;
// }
//
// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(20px + 2vmin);
//   color: white;
// }
//
// .App-link {
//   color: #61dafb;
// }

$textMuted: #6c757d;

#about {
  padding-bottom: 80px;
  .tagline {
    text-align: center;
    font-style: italic;
    margin: 20px;
  }
}

svg {
    font: 10px sans-serif;
    display: inline;
}

.key path {
    display: none;
}

.key line {
    stroke: #000;
    shape-rendering: crispEdges;
}

.key text {
    font-size: 10px;
}
#map, #list-container {
  display: inline-block;
  float: left;
}
#list-container {
  width: 300px;
}
#town-name {
  text-transform: capitalize;
}

#rarities {
  max-width: 1000px;
  >.row {
    padding: 0px 20px;
  }
}

span.nav-link {
 cursor: pointer;
}

blockquote {
  padding-left: 20px;
  font-style: italic;
}

h1 {
  padding-bottom: 50px;
}

h2 {
  padding-bottom: 20px;
}

.table-row {
  padding-top: 20px;
  padding-bottom: 20px;
}

div.page {
  margin: 40px auto;
  padding-bottom: 50px;
}

.banner-image {
  max-width: 100%;
  margin-bottom: 30px;
}

.container-md {
  padding-top: 20px;
}

.navbar-light {
  background-color: #98B8CF;
  // color: black;
  .navbar-nav .nav-link {
    color: black;
  }
}

.footer {
  text-align: center;
  svg {
    color: $textMuted;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.mapButton {
  padding-top: 20px;
  padding-bottom: 20px;
}

.csv-container {
  text-align: center;
  .csv-input {
    padding: 10px;
    display: block;
    margin: 15px auto;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
}

h3 {
  padding-top: 50px;
}